import React from "react"

export const TitleUnderlineTestimonyOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="137" height="10" viewBox="0 0 137 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.4092 8.72465C22.2418 7.97829 1.65098 10.1013 0.471203 9.99622C-0.778311 9.75296 0.73273 3.89821 1.8137 2.96941C2.47624 2.4055 2.42974 2.81461 8.14264 2.4055C8.14264 2.4055 16.7556 1.80842 27.3445 1.11182C27.3445 1.11182 44.3553 0.210661 67.7764 0.000575625C69.6187 -0.0160101 68.16 0.332289 70.3277 0.210661C74.948 -0.0381243 85.0023 0.431804 88.5009 0.232775C91.3079 0.072447 91.9124 0.0613902 94.5916 0.365461C96.4339 0.575547 111.893 1.09523 113.607 0.780103C114.026 0.702703 114.328 0.75246 114.322 0.879617C114.444 0.890674 124.574 1.64809 124.621 1.76419C124.69 1.89687 124.998 1.9798 125.295 1.91346C125.841 1.7863 136.796 2.17883 136.941 2.56583C137.36 3.67154 135.448 8.03911 134.105 9.05083C132.716 10.0957 125.981 8.26025 120.029 8.57538C92.2727 6.94445 92.4877 7.35909 87.85 7.57471C87.2107 7.17112 84.6884 8.03911 83.189 7.34804C82.5613 7.05502 78.0515 6.94445 77.0402 7.19324C76.8252 7.24852 75.2154 7.23746 72.8907 7.19876C71.7284 7.18218 70.3859 7.15453 68.9504 7.13242C67.0325 7.09925 52.5207 6.94998 50.7017 7.59129C45.8082 6.94998 30.0643 8.33765 25.4092 8.72465Z" fill="#FFBA00"/>
      </svg>
  </span>
)

export const TitleUnderlineTestimonyTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}      
      width="155" height="10" viewBox="0 0 155 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.7476 8.72465C25.1641 7.97829 1.86789 10.1013 0.533113 9.99622C-0.880571 9.75296 0.829001 3.89821 2.052 2.96941C2.80158 2.4055 2.74898 2.81461 9.21247 2.4055C9.21247 2.4055 18.957 1.80842 30.9372 1.11182C30.9372 1.11182 50.183 0.210661 76.6813 0.000575624C78.7657 -0.0160101 77.1153 0.332289 79.5679 0.210661C84.7952 -0.0381242 96.1704 0.431803 100.129 0.232775C103.305 0.072447 103.988 0.0613902 107.02 0.365461C109.104 0.575546 126.594 1.09523 128.534 0.780103C129.007 0.702703 129.349 0.75246 129.343 0.879617C129.481 0.890674 140.941 1.64809 140.994 1.76419C141.073 1.89687 141.421 1.9798 141.757 1.91346C142.375 1.7863 154.769 2.17883 154.934 2.56583C155.407 3.67154 153.244 8.0391 151.725 9.05083C150.153 10.0957 142.533 8.26025 135.8 8.57538C104.396 6.94445 104.639 7.35909 99.3923 7.57471C98.669 7.17112 95.8154 8.03911 94.119 7.34803C93.4088 7.05502 88.3064 6.94445 87.1623 7.19324C86.919 7.24852 85.0977 7.23746 82.4676 7.19876C81.1525 7.18218 79.6336 7.15453 78.0095 7.13242C75.8397 7.09925 59.4212 6.94998 57.3632 7.59129C51.8268 6.94998 34.0144 8.33765 28.7476 8.72465Z" fill="#FFBA00"/>
      </svg>
  </span>
)