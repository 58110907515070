import React from "react"
import { Link } from "gatsby"
import imgDesktop from "./image/iconsDesktop.webp"
import imgMobile from "./image/iconsMobile.svg"

const Integrations = ({ location }) => {
    return (
        <section className="container-integrations">
            <section className="container-integrations-info">
                <section>
                    <p className="container-integrations-info-title">
                        Potencia tu gestión omnicanal integrando Beex y sus canales con tus aplicaciones favoritas
                    </p>
                    <p>
                        Centraliza totalmente tu operación integrando Beex y sus canales con tus plataformas de gestión de clientes y productos, como Salesforce, HubSpot, Zoho, Shopify, VTEX y muchos más.
                        <br /><br />
                        Intégralo fácilmente haciendo uso de nuestra API escalable. Diseña y pon en producción tus escenarios ideales de gestión, logrando automatizar tus procesos y obteniendo una vista 360 de tus clientes y sus interacciones.
                    </p>
                    <Link
                        to={`/hablemos/${location.search}`}
                        className="container-integrations-info-button"
                    >
                        <span>Contacta con ventas </span>
                    </Link>
                </section>
                <img
                    className="container-integrations-info-image"
                    srcSet={imgDesktop}
                    alt="suport"
                    loading="lazy"
                />
            </section>
            <div className="container-integrations-nubeDesktop">
                <svg width="341" height="216" viewBox="0 0 341 216" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.11666 216H305.224C327.03 200.412 341 175.599 341 147.923C341 100.524 299.773 62.0324 249.006 62.0324C243.213 62.0324 237.421 62.6686 231.629 63.623C218.682 26.7216 181.543 0 137.59 0C93.2967 0 56.1579 27.0398 43.2106 64.2592C2.32417 73.1664 -28 107.205 -28 147.923C-27.6593 175.599 -13.6894 200.094 8.11666 216Z" fill="white" fill-opacity="0.6" />
                </svg>
                <svg width="392" height="213" viewBox="0 0 392 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.3152 213H388.458C406.91 198.78 419 176.693 419 152.186C419 108.92 382.095 74.1264 336.918 74.1264C332.464 74.1264 328.328 74.4289 324.192 75.034C319.102 32.6761 281.56 0 235.747 0C200.115 0 169.254 19.9687 154.938 48.7116C153.029 48.409 150.802 48.4091 148.893 48.4091C122.805 48.4091 100.853 66.26 95.7624 89.8594C89.0814 88.044 81.7639 86.8338 74.4465 86.8338C33.4055 86.8338 0 118.602 0 157.632C0 180.021 11.1352 199.99 28.3152 213Z" fill="white" fill-opacity="0.6" />
                </svg>
            </div>
            <div className="container-integrations-nubeMobile">
                <img
                    srcSet={imgMobile}
                    alt="suport"
                    loading="lazy"
                />
            </div>
        </section>
    )
}
export default Integrations
